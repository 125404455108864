<template>
  <main class="main">
    <section class="section">
      <div class="container">
        <div class="page__wrap">

          <div class="page__content">
            <div class="sidebar__header">
              <h1 class="sidebar__title">Clientes</h1>
              <div class="buttons">
                <button
                  type="button"
                  class="button_outline"
                  @click="openModal()"
                >
                  Cadastrar Cliente
                </button>
              </div>
            </div>
            <div class="tabs__flex">
              <!-- <div class="tabs__nav">
                <a class="tabs__anchor current" href="#all" title="Todos"
                  >Todos</a
                >
                <a class="tabs__anchor" href="#fisica" title="Pessoa Física"
                  >Pessoa Física</a
                >
                <a class="tabs__anchor" href="#juridica" title="Pessoa Jurídica"
                  >Pessoa Jurídica</a
                >
              </div> -->

              <div></div>
              <div class="tabs__search">
                <form class="search__form">
                  <input
                    class="form__input"
                    type="text"
                    placeholder="Pesquisar..."
                    @input="debounceInput"
                  />
                  <button class="button search__button" type="submit">
                    <i class="icon icon-search"></i>
                  </button>
                </form>
              </div>
            </div>

            <div class="tab__body current" id="all">
              <table class="table">
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Email</th>
                    <th>Telefone</th>
                    <th>Cidade/Estado</th>
                    <th>Opções</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="client in clients" :key="client.id">
                    <td class="flex-td">
                      <span
                        class="circle_initial"
                        style="background-color: #16b666;"
                      >
                        {{ client.name[0] }}
                      </span>
                      <span> {{ client.name }}</span>
                    </td>
                    <td>
                      <span>{{ client.email }}</span>
                    </td>
                    <td>
                      <span>{{ client.phone }}</span>
                    </td>
                    <td>
                      <span>{{ client.city }} - {{ client.province }}</span>
                    </td>

                    <td>
                      <i
                        class="fas fa-edit edit_icon"
                        @click="openEditModal(client)"
                      ></i>
                      <i
                        class="far fa-trash-alt delete_icon"
                        @click="deleteClient(client.id)"
                      ></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <paginator
          v-model="pagination.page"
          :pages="pagination.pages"
          v-show="clients.length"
        />
      </div>
    </section>

    <modal-register
      :editing.sync="editing"
      :modal.sync="modal"
      @change-modal="e => (modal = e)"
      @reload-list="getClients"
    ></modal-register>
  </main>
</template>

<script>
import { mapActions } from 'vuex'
import _ from 'lodash'

export default {
  data: () => ({
    error: null,
    load: true,
    modal: false,
    editing: {},
    msg: null,
    clients: [],
    search: '',
    pagination: {
      page: 1,
      pages: 1
    }
  }),
  components: {
    modalRegister: () => import('./components/modal-register-client'),
    paginator: () => import('@/components/paginator.vue')
  },
  props: ['nome'],
  methods: {
    ...mapActions('client', ['fetch']),
    openEditModal (item = null) {
      this.editing = {}
      if (!item) return
      const itemToEdit = { ...item }
      this.editing = itemToEdit
      this.modal = true
    },
    async deleteClient (item = null) {
      const url = 'clients/destroy'

      this.$http.delete(url, { body: { id: item } }).then(response => {
        this.msg = response.msg
      })
    },
    openModal (item = null) {
      this.selectedEntity = item
      this.modal = true
    },
    async getClients (currentPage = this.pagination.page) {
      let url = `clients?page=${currentPage}`
      if (this.search) url += `&search=${this.search}`
      const { data } = await this.$http.get(url)
      this.pagination = { page: data.current_page, pages: data.last_page }
      this.clients = data.data
    },

    debounceInput: _.debounce(function (e) {
      this.search = e.target.value
    }, 300)
  },
  mounted () {
    this.getClients()
  },
  filters: {
    personTypeColor (val) {
      if (val === 'PF') return 'background-color: #f3e1fd; color: #9532e3;'
      return 'background-color: #DFF5FF; color: #2976E4;'
    },
    personType (val) {
      const types = { PF: 'Pessoa Física', PJ: 'Pessoa Jurídica' }
      return types[val]
    }
  },
  watch: {
    'pagination.page' (val, old) {
      if (val === old) return null
      this.getClients(val)
    },
    search (val, old) {
      if (val === old) return null
      this.getClients()
    }
  }
}
</script>

<style>
.sidebar__header {
  display: flex;
  justify-content: space-between;
}
.tabs__search {
  width: 40%;
}

.table {
  table-layout: fixed;
}

th,
td {
  font-weight: 400;
  width: 100%;
  text-align: left;
}

td span {
  font-weight: 400;
  font-size: 16px;
}

.table tbody tr td:nth-child(1) {
  width: 200px;
  display: inline-flex;
}

.alert_card {
  font-size: 10pt;
  text-align: center;
  padding: 3px;
  border-radius: 4px;
  width: 50px;
}

.circle_initial {
  width: 30px;
  height: 30px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 7px;
  position: relative;
}

.circle_initial p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.flex-td {
  display: flex;
  align-items: center;
}
</style>
